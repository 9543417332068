import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62ba438e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "area-list" }
const _hoisted_3 = { class: "first-floor" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "second-floor" }
const _hoisted_6 = { class: "second-floor-c" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areaList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "area-list-item",
          key: index
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(item.projectArea.name), 1),
            (item.children.length > 0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "arrow",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fOpenCard && _ctx.fOpenCard(...args))),
                  src: require('@/assets/download-arrow.png')
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (citem, cindex) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "area-name",
                  key: cindex
                }, _toDisplayString(citem.name), 1))
              }), 128))
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}
import request from "@/utils/request";

/**
 * 分页查询工地列表
 */
export function listAreaTree(data: any) {
  return request({
    url: "/project/projectArea/listAreaTree",
    method: "get",
    params: data,
  });
}
/**
 * 分页查询巡检记录
 */
export function projectAreaCheckPage(data: any) {
  return request({
    url: "/project/projectAreaCheck/page",
    method: "get",
    params: data,
  });
}
/**
 * 删除
 */
export function projectAreaCheckRemove(data: any) {
  return request({
    url: "/project/projectAreaCheck/remove",
    method: "get",
    params: data,
  });
}
/**
 * 文件列表
 */
export function listFiles(data: any) {
  return request({
    url: "/project/projectAreaCheck/listFiles",
    method: "get",
    params: data,
  });
}
/**
 * 文件列表
 */
export function saveCheck(data: any) {
  return request({
    url: "/project/projectAreaCheck/save",
    method: "post",
    data: data,
  });
}

/**
 * 全部问题列表
 */
export function projectAreaCheckpageAll(params: any) {
  return request({
    url: "/project/projectAreaCheck/pageAll",
    method: "get",
    params: params,
  });
}
/**
 * 通知我的问题列表
 */
export function projectAreaCheckpageNotice(params: any) {
  return request({
    url: "/project/projectAreaCheck/pageNotice",
    method: "get",
    params: params,
  });
}
/**
 * 我创建的问题列表
 */
export function projectAreaCheckpageCreate(params: any) {
  return request({
    url: "/project/projectAreaCheck/pageCreate",
    method: "get",
    params: params,
  });
}
/**
 * 我处理的问题列表
 */
export function projectAreaCheckpageDeal(params: any) {
  return request({
    url: "/project/projectAreaCheck/pageDeal",
    method: "get",
    params: params,
  });
}

/**
 * 漏检列表
 */
export function projectAreaCheckLogpage(params: any) {
  return request({
    url: "/project/projectAreaCheckLog/page",
    method: "get",
    params: params,
  });
}

// 参建单位列表
export function listProjectCompany(params: any) {
  return request({
    url: "/project/projectDept/listProjectCompany",
    method: "get",
    params: params,
  });
}

// 新增区域
export function projectAreaSave(arg: any, pid: any) {
  return request({
    url: "/project/projectArea/save",
    method: "POST",
    data: arg,
    headers: { pId: pid },
  });
}

// 新增安全隐患的位置
export function dangerSourceSave(arg: any) {
  return request({
    url: "/project/danger/source/save",
    method: "POST",
    data: arg,
  });
}

// 新增安全隐患的类型
export function dangerTypeSave(arg: any) {
  return request({
    url: "/project/danger/type/save",
    method: "POST",
    data: arg,
  });
}

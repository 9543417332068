
import { Vue } from "vue-property-decorator";
import * as api from "@/api/area.ts";
export default class extends Vue {
  areaList = [];
  fOpenCard(e: any) {
    const ele = e.currentTarget.parentElement;
    const next = ele.nextElementSibling;

    e.currentTarget.classList.toggle("_roate");
    if (e.currentTarget.classList.contains("_roate")) {
      next.classList.add("expend");
    } else {
      next.classList.remove("expend");
    }
  }
  mounted() {
    this.getData();
  }
  async getData() {
    const res: any = await api.listAreaTree({
      id: 0,
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.areaList = res.data;
  }
}
